import React, { useMemo, useState } from 'react';
import { OverlayTrigger, Tooltip, Button, Table } from 'react-bootstrap';
import styled from 'styled-components';
import { observeStorage, useOnResetChapter, useStorage } from '../../templates/localStorageContext';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

const TRWithInputs = styled.tr`
  height: 100%;

  td {
    height: 100%;

    &.checkbox-column {
      min-width: 30px;
      padding: 5px;
    }

    &.select-column {
      min-width: 90px;
      padding: 10px 5px;
    }

    input[type='text'] {
      width: 80px;
    }

    input[type='select'] {
      font-size: 0.8rem;
    }
  }
`;

const selectColorOptions = [
  { value: '28a745', label: 'Groen' },
  { value: 'ffc107', label: 'Geel' },
  { value: 'dc3545', label: 'Rood' },
];

const FormPrefix = 'logboek_question';
const LogboekQuestion = observeStorage(
  ({ id, title, explanation, tooltip, columnTitles, rowTitles }) => {
    const storage = useStorage();
    const keyForStorage = `logboek_question_${id}`;

    // Get data consistently
    const dataFromStorage = useMemo(
      () => storage.dataWithChanges?.[keyForStorage] ?? storage.getItem(keyForStorage) ?? {},
      [keyForStorage, storage]
    );

    const { register, watch, getValues, setValue, control } = useForm({
      defaultValues: dataFromStorage ?? {},
      ...Object.fromEntries(
        Object.entries(dataFromStorage ?? {}).map(([key, value]) =>
          key.includes('_checkbox_') ? [key, !!value] : [key, value]
        )
      ),
    });

    watch((data) => {
      storage.setItems({ [keyForStorage]: data });
    });

    useOnResetChapter(() => {
      // reset the form
      const fieldsInForm = Object.keys(getValues());
      for (const field of fieldsInForm) {
        setValue(field, '');
      }
      storage.setItems({ [keyForStorage]: {} });
    });

    const { rowHeader, columnTitlesArray } = useMemo(() => {
      if (columnTitles.includes('|')) {
        var columns = columnTitles.split('|');
        return {
          rowHeader: columns[0],
          columnTitlesArray: columns[1].split(';'),
        };
      } else {
        return { rowHeader: '', columnTitlesArray: columnTitles.split(';') };
      }
    }, [columnTitles]);

    const rowTitlesArray = rowTitles.split(';');
    const nbColumns = columnTitlesArray.length;

    const [hideSelect, setHideSelect] = useState(false);
    const hideColorSelects = () => {
      setHideSelect((prev) => !prev);
    };

    return (
      <section className="logboek-question-repeatable content-section text-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 wysiwyg">
              <div className="question">
                <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
                <div className="d-flex justify-content-between">
                  <div
                    className="text-wrapper wysiwyg"
                    dangerouslySetInnerHTML={{ __html: explanation }}
                  />
                  {tooltip !== null && (
                    <div className="tooltipQuestion">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip id={`tooltip-left`}>{tooltip}.</Tooltip>}
                      >
                        <Button className="tooltipButton" variant="">
                          ?
                        </Button>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
                <div className="row justify-content-end">
                  <div className="col-auto">
                    <Button className="" variant="secondary" onClick={hideColorSelects}>
                      {hideSelect === true ? 'TOON KLEUREN OPTIES' : 'VERBERG KLEUREN OPTIES'}
                    </Button>
                  </div>
                </div>

                <Table bordered responsive hover>
                  <thead>
                    <tr>
                      <th>{rowHeader}</th>
                      {columnTitlesArray.map((item, index) => {
                        return (
                          <th
                            key={index}
                            className="text-center columnTableQuestion day-column"
                            colSpan="2"
                          >
                            {item}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {rowTitlesArray.map((item, i) => {
                      return (
                        <TRWithInputs key={i}>
                          <td>
                            <p>{item}</p>
                          </td>
                          {Array.from({ length: nbColumns }, (_, j) => [
                            <Controller
                              control={control}
                              name={`${FormPrefix}_${id}_checkbox_${i}_${j}`}
                              defaultValue={false}
                              key={`${i}${j}0`}
                              render={({ field: { value, ...fieldProps } }) => (
                                <td
                                  key={`${i}${j}0`}
                                  className={`text-center checkbox-column ${
                                    value === true ? 'bg-dark-gray' : 'bg-gray'
                                  }`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`${FormPrefix}_${id}_checkbox_${i}_${j}`}
                                    checked={value || false}
                                    {...fieldProps}
                                  />
                                  <label htmlFor={`${FormPrefix}_${id}_checkbox_${i}_${j}`}></label>
                                </td>
                              )}
                            />,
                            <td
                              key={`${i}${j}1`}
                              className="text-center select-column position-relative"
                            >
                              <Controller
                                control={control}
                                name={`${FormPrefix}_${id}_select_color_${i}_${j}`}
                                render={({ field }) => (
                                  <>
                                    <Select
                                      className={`mb-3 ${
                                        hideSelect === true ? 'd-none' : 'd-block'
                                      }`}
                                      classNamePrefix="select"
                                      isSearchable={true}
                                      placeholder="kleur"
                                      options={selectColorOptions}
                                      {...field}
                                    />
                                    <div
                                      className="position-absolute w-100 h-100"
                                      style={{
                                        backgroundColor: field.value
                                          ? `#${field.value.value}`
                                          : '#fff',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: -1,
                                      }}
                                    ></div>
                                  </>
                                )}
                              />

                              <input
                                type="text"
                                className="form-control"
                                {...register(`${FormPrefix}_${id}_input_mood_${i}_${j}`)}
                              />
                            </td>,
                          ])}
                        </TRWithInputs>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default LogboekQuestion;
